// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "chartkick/chart.js"
require("jquery");
import "@fortawesome/fontawesome-free/css/all"
import "../bolada/notyf";

import "../bolada/numeral";
import "../bolada/moment";

Rails.start();
ActiveStorage.start();

import "./serviceworker-companion";

import "controllers";

import "../bolada/bootstrap";

import "../bolada/store";
import consumer from "../channels/consumer";

document.addEventListener("DOMContentLoaded", () => {
  consumer.subscriptions.create("AtualizacaoChannel", {
    connected: function () {
    },

    disconnected: function () {
    },

    received: function (data) {
      $('#' + data.id).html(data.html);
    }
  });

  consumer.subscriptions.create("JogadorChannel", {
    connected: function() {
    }, 

    received: function(data) {
      // data.tipo: msg / saldo
      // data.msg.tipo: success, error, waring, info
      // data.msg.texto: texto da mensagem
      // data.saldo = 0.00
      if (data.tipo == "saldo") {
        document.querySelectorAll(".jogador_saldo").forEach((el) => {
          el.innerText = data.saldo + "💰";
        });

        if (data.html && data.htmlId) {
          $('#' + data.htmlId).html(data.html);
        }

      } else if (data.tipo == "msg") {
        if (data.msg && data.msg.tipo && data.msg.texto) {
          let notification = notyf.open({
            type: data.msg.tipo,
            message: data.msg.texto,
          });

          if (data.msg.link) {
            notification.on("click", ({ target, event }) => {
              window.location.href = data.msg.link;
            });
          }
          
        } else {
          console.log("Formato inválido da mensagem!");
        }
      } else if (data.tipo == "limpar_tudo") {
        Spruce.store("bilhete").apostas = []
      }  
    },
  });

  $("#data-filter").change(function () {
    $.ajax({
      url: this.form.action,
      type: "GET",
      data: {
        periodo_dias: $("#data-filter").val(),
      },
      // , success: function(result) {   $('#tabela_apostas').html(result);   marcar_apostado(); }
    });
  });

  function checkNotifs(obj) {
    if (!("Notification" in window)) {
      //1
      console.error("This browser does not support desktop notification");
    }
    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      //2
      console.log("Permission to receive notifications has been granted");
      getKeys();
    }
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      //3
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          //4
          console.log("Permission to receive notifications has been granted");
          getKeys();
        }
      });
    }
  }

  checkNotifs();

  function getKeys() {
    navigator.serviceWorker
      .register("/serviceworker.js", {
        scope: "./",
      }) //5
      .then(function (registration) {
        return registration.pushManager
          .getSubscription()
          .then(function (subscription) {
            if (subscription) {
              return subscription;
            }
            return registration.pushManager.subscribe({
              //6
              userVisibleOnly: true,
            });
          });
      })
      .then(function (subscription) {
        sendKeys(subscription.toJSON()); //7
      });
  }

  function sendKeys(subscription) {
    $.ajax({
      url: "/notification_datum",
      type: "POST",
      data: subscription,
    });
  }

  function pesquisarJogos() {
    let iconPesquisar = $('#icon-pesquisar')
    let termo = $('#campo-pesquisar').val();
    iconPesquisar.removeClass('bi bi-search');
    iconPesquisar.addClass('spinner-border spinner-border-sm text-light');
    $.ajax({
      url: "/pes?termo=" + termo,
      type: "GET",
      dataType: 'json',
      success: function (data) {
        Spruce.store("jogo").jogos = data;
        iconPesquisar.removeClass('spinner-border spinner-border-sm text-light');
        iconPesquisar.addClass('bi bi-search');
      }
    });
  }

  $("#pesquisar").click(function () {
    pesquisarJogos();
  });

  $('#campo-pesquisar').on('keypress', function (e) {
    if (e.which == 13) {
      pesquisarJogos();
    }
  });


});

