import "@popperjs/core"
import * as bootstrap from "bootstrap";

import "swiped-events";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./scss/index.scss"


window.bootstrap = bootstrap; // Para funcionar com format.js do rails

const onLoaded = ()=> {

  // modal showing
  window.showModal = (selector) => {
    const modalShowing = document.querySelector(selector);
    if (modalShowing) {
      const options = {
        backdrop: modalShowing.dataset.bsBackdrop || "",
      };
      new bootstrap.Modal(modalShowing, options).show();
    }
  };

  showModal(".modal.modal-showing");

  window.removeByQuerySelector = (selector) => {
    const el = document.querySelector(selector);
    if (el) {
      el.remove();
    }
  };

  window.insertAdjacentHTML = (selector, html) => {
    removeByQuerySelector(selector);
    document.querySelector("body").insertAdjacentHTML("beforeend", html);
  };
}

// document.addEventListener("ReBindMenu", reBindMenu);
document.addEventListener("DOMContentLoaded", onLoaded);
