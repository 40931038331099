import { Notyf } from "notyf";
import "notyf/notyf.min.css";

document.addEventListener("DOMContentLoaded", () => {
  window.notyf = new Notyf({
    dismissible: true,
    duration: 5000,
    position: {
      x: "center",
      y: "top",
    },
    types: [
      {
        type: "success",
        className: "bg-success vw-100"
      },
      {
        type: "error",
        className: "bg-danger vw-100"
      },
      {
        type: "warning",
        className: "bg-warning vw-100"
      },
      {
        type: "info",
        className: "bg-info vw-100"
      },
    ],
  });
});
