
// import moment from "moment";
import moment from "moment-timezone";
import 'moment/locale/pt-br'



moment.locale('pt-br')
moment.tz.setDefault('America/Sao_Paulo');

window.moment = moment;
// window.momentTimezone = momentTimezone;

export default moment;